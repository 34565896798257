<template>
  <div v-if="crumbs.length > 0" class="breadcrumbs">
    <router-link to="/">Home</router-link>
    <component :is="$index === crumbs.length - 1 ? 'span': 'router-link'" v-for="(link, $index) in crumbs" :to="link.to" :key="`crumb_link_${$index}`" :disabled="$index === crumbs.length - 1">
      {{ link.text }}
    </component>
  </div>
</template>
<script>
export default {
  name: 'BreadCrumbs',
  computed:{
    crumbs: function() {
      if (this.$route.path === '/') return []

      const pathArray = this.$route.path.split("/")
      pathArray.shift()

      const breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        if (this.$route.matched[idx]) {
          breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
              ? "/" + breadcrumbArray[idx - 1].path + "/" + path
              : "/" + path,
            text: (this.$route.matched[idx].meta.breadCrumb || path).replace('-', ' '),
          });
        }
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  }
}
</script>
<style lang="scss" scoped>
.breadcrumbs {
  position: sticky;
  background: $white;
  top: 78px;
  z-index: 2;
  padding: 1em 0;
  & a {
    text-decoration: none;
    display: inline-block;
    cursor: pointer;
    margin-right: .5em;
    text-transform: capitalize;
    color: $dark-indigo;
    &::after {
      content: '/';
      margin-left: .5em;
    }
  }
  & span {
    display: inline-block;
    color: $grey;
    text-transform: capitalize;
    cursor: not-allowed;
  }
}
</style>