<template>
    <div v-if="user">
        <bread-crumbs />
        <router-view />
    </div>
</template>
<script>
import BreadCrumbs from '@/components/commons/BreadCrumbs'
import { mapGetters } from 'vuex'

export default {
    name: 'Dashboard',
    components: {
        BreadCrumbs
    },
    computed: {
        ...mapGetters({
            user: 'account/user'
        })
    }
}
</script>